export default function SeasonMenu() {
  return(
    <section className="seasonmenu-full-cont">
      <div className="season-cont">
        <h1 className="big-title-forside">Sæson Menu</h1>
        <h2 className="sub-big-title-forside" id="frokost-subtitle">Menu de saison</h2>
      </div>
      <div className="seasonmenu-cont">
        <h1 className="seasonmenu-dish">Forret</h1>
        <h2 className="seasonmenu-name">Cremet jordskokkesuppe</h2>
        <p className="seasonmenu-text">Serveres med syltede jordskokker, mayo, tigerrejer og croutons</p>
      </div>
      <div className="seasonmenu-cont">
        <h1 className="seasonmenu-dish">Hovedret</h1>
        <h2 className="seasonmenu-name">Stegt oksefilet</h2>
        <p className="seasonmenu-text">Serveres med syltede beder, rodfrugter, peberrod og brun sauce</p>
      </div>
      <div className="seasonmenu-cont">
        <h1 className="seasonmenu-dish">Dessert</h1>
        <h2 className="seasonmenu-name">Mazarinkage</h2>
        <p className="seasonmenu-text">Serveres med sorbet, is citrus coulis og chokolade krumble</p>
      </div>
      <div className="price-cont">
        <p className="price-text">1 ret 259,-</p>
        <p className="price-text">2 retter 299,- plus vinmenu 449,-</p>
        <p className="price-text">3 retter 349,- plus vinmenu 569,-</p>
      </div>
    </section>
  )
}