import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import logo from '../img/logo.png'
import chokri from '../img/chokri.jpg'

export default function Forside() {
  return(
    <main>
      <section className="background-picture">
        <div className='background-pic-cont'>
        <div className="title-forside-cont">
          <button className='booking-forside'><a className='link' href='https://book.easytablebooking.com/book/?id=d74a9&lang=auto'>BOOK ET BORD</a></button>
          <img src={logo} alt='brasserie Piaf' className='logo-forside'></img>
          <h1 className="title-forside">BRASSERIE PIAF</h1><br></br>
          <h2 className="title-forside" id="sub-title-forside">CAFÉ & BRASSERIE</h2>
        </div>
        <div className="arrows-cont shake-vertical">
          <KeyboardDoubleArrowDownIcon onClick={() => { window.scrollTo({top: 750, left: 0, behavior: 'smooth'});}} className="arrowsdown" sx={{ fontSize: "3.5rem" }}/>
        </div>
        </div>
      </section>
      <section>
        <div className='big-title-forside-cont'>
          <h1 className='big-title-forside'>Bienvenue chez</h1>
          <h2 className='sub-big-title-forside'>BRASSERIE PIAF</h2>
          <div className='line-forside'></div>
          <p className='text-forside'>Duet fransk køkken og dansk hygge. Brasserie Piaf er et fransk brasserie med en uformel og rustik atmosfære, og kulinariske oplevelser. Vores menu er inspireret af det gamle klassiske franske køkken.
          Vi vil have gæster til at føle sig hjemme hos os. Kaffen vil være åben fra klokken 11.00 om morgenen og til sent om aftenen. Frokost serveres fra kl. 11.00 til kl. 15.00 og aftensmad fra kl. 17.00 til sent om aftenen.</p>
        </div>
        <div className='second-part-forside-cont'>
          <div className='image-forside-cont'>
            <img className='image-forside' src={chokri} alt='forret Piaf'></img>
          </div>
          <div className='text-big-cont'>
            <div className='second-part-cont'>
              <h1 className='sub-big-title-forside second-part-margin'>ARRANGEMENTER</h1>
              <p className='text-forside text-forside-margin'>Her på Brasserie Piaf kan du booke et selskab. Hvis du er mere end 8 personer, kan du kontakte os, så booker vi en plads til dig.Hvis du har lyst til at komme og nyde vores Brasserie, kan du ringe til os her eller sende os en mail. Vi venter på dig!</p>
            </div>
            <div className='second-part-cont last-para'>
              <h1 className='sub-big-title-forside second-part-margin'>GAVE KORT</h1>
              <p className='text-forside text-forside-margin'>Hvis du vil have et gavekort, laver vi nogle. Du kan komme direkte på restauranten og se med vores personale for at få din!</p>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}