export default function Frokost() {
  return(
    <section>
      <div className="frokost-cont">
        <h1 className="big-title-forside">Frokost</h1>
        <h2 className="sub-big-title-forside" id="frokost-subtitle">Déjeuner</h2>
      </div>
      <section className="menu-frokost-cont">
        <div className="desktop-layout-frokost">
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">SNEGLE 75,-</h3>
            <p className="description-frokost">Persille & hvidløg med chips</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">NACHOS</h3>
            <p className="description-frokost">M. cheddarost, salsa & guacamole 90,-</p>
            <p className="description-frokost">M. kyyling 120,-</p>
          </div>
          <div className="text-frokost-cont">
            <h2 className="text-frokost">LES CLASSIQUES</h2>
            <div className="line-frokost"></div>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">FROKOST PLATTE 145,-</h3>
            <p className="description-frokost">3 charcuterie, 2 slags ost, dips, nødder & oliven, surt & sødt, brød & smør</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">SALADE CESAR 128,-</h3>
            <p className="description-frokost">Salat med confitere kylling, hjertesalat, permesanost, syltede løg & cæserdressing</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">RØRT OKSETATAR 149,-</h3>
            <p className="description-frokost">Serveres med dijon vnaigrette, cornichoner, chilimayo & pomfritter</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">MOULES FRITES 129,-</h3>
            <p className="description-frokost">Hvidløgs dampet blåmuslinger i cremet suppe med harissa mayo & pomfritter</p>
          </div>
        </div>
        <div className="desktop-layout-frokost">
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">KANINLÅR 129,-</h3>
            <p className="description-frokost">Serveres med salat</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">STEAK RIBEYE 239,-</h3>
            <p className="description-frokost">Serveres med bearnaisesauce, pomfritter & salat</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">FISH & CHIPS 129,-</h3>
          </div>
          <div className="text-frokost-cont">
            <h2 className="text-frokost">LES SANDWICHES & BURGER</h2>
            <div className="line-frokost"></div>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">BRIOCHE BURGER 145,-</h3>
            <p className="description-frokost">Hakket bøf, cheddarost, marineret salat, syltede løg, pomfritter & mayo</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">VEGETAR BURGER 135,-</h3>
            <p className="description-frokost">Hjemmelavet vegetarburger, cheddarost, syltede løg, pomfritter & mayo</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">CROQUE MONSIEUR 110,- / MADAME 130,-</h3>
            <p className="description-frokost">Brioche toast, landskinke, emmentalerost & dijon senneps</p>
          </div>
          <div className="text-frokost-cont">
            <h2 className="text-frokost">LES SUCRERIES</h2>
            <div className="line-frokost"></div>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">CRÈME BRULÉE 75,-</h3>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">GATEAU MARCEL 75,-</h3>
          </div>
        </div>
      </section>
    </section>
  )
}