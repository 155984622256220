import { Routes, Route } from 'react-router-dom';
import Forside from '../pages/Forside';
import Kontakt from '../pages/Kontakt.js';
import Frokost from '../pages/Frokost';
import SeasonMenu from '../pages/SeasonMenu';
import Aftenkort from '../pages/Aftenkort';
import Footer from './Footer'
import Layout from './Layout'

const App = () => {
  return (
    <>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Forside />} />
          <Route path="frokost" element={<Frokost />} />
          <Route path="aftenkort" element={<Aftenkort />} />
          <Route path="Seasonmenu" element={<SeasonMenu />} />
          <Route path="kontakt" element={<Kontakt/>} />
          <Route path="*" element={<p>Not found!</p>} />
        </Route>
      </Routes>
      <Footer/>
    </>
  );
};

export default App;