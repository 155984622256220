import aftenkort from '../img/maindish.jpg'

export default function Aftenkort() {
  return(
    <section>
      <div className="aftenkort-top-cont">
        <img src={aftenkort} alt='aftenkort Piaf' className='image-top-aftenkort'/>
        <h1 className="aftenkort-big-title">Aftenkort</h1>
        <h2 className="aftenkort-subtitle">Diner</h2>
      </div>
      <section className="menu-frokost-cont">
        <div className="desktop-layout-frokost">
          <div className="text-frokost-cont">
            <h2 className="text-frokost">FORRETTER</h2>
            <div className="line-frokost"></div>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">ANDERILLETER 79,-</h3>
            <p className="description-frokost">Karse, syltede svampe & balsamico mayo</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">CHÈVRE CHAUD 79,-</h3>
            <p className="description-frokost">Gratineret gedeost, oliven, salat & valnødder</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">MOULES MARINIÈRES 79,- / 169,-</h3>
            <p className="description-frokost">Blåmuslinger tilberedt i fløde & hvidvin</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">OKSETATAR 79,- / 179,-</h3>
            <p className="description-frokost">Serveres med chilimayo, pocheret æg & chips</p>
          </div>
        </div>
        <div className="desktop-layout-frokost">
        <div className="text-frokost-cont">
            <h2 className="text-frokost">HOVEDRETTER</h2>
            <div className="line-frokost"></div>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">OKSEBURGER 175,-</h3>
            <p className="description-frokost">Briochebolle, hakket bøf, ost, syltede løg, pomfritter & mayo</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">POISSON DU JOUR 210,-</h3>
            <p className="description-frokost">Dagens fisk, Spørg venligst tjeneren</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">STEAK RIBEYE 280,-</h3>
            <p className='description-frokost'>Hjemmelavet bearnaisesauce, pomfritter & salat</p>
          </div>
          <div className="text-frokost-cont">
            <h2 className="text-frokost">DESSERTER</h2>
            <div className="line-frokost"></div>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">CRÈME BRULÉE 75,-</h3>
            <p className="description-frokost">Serveres med is sorbet</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">GATEAU MARCEL 80,-</h3>
            <p className="description-frokost">Serveres med is parfait</p>
          </div>
          <div className="subtext-frokost-cont">
            <h3 className="subtext-frokost">ASSIETTE DE FROMAGE</h3>
            <p className="description-frokost">Ostebræt med europæiske ost</p>
          </div>
        </div>
      </section>
    </section>
  )
}