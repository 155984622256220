import Navbar from './Navbar';
import { Link } from 'react-router-dom';
import logo from '../img/logo.png'
import coq from '../img/coq-francais.png'

const Header = () => {
  return (
    <header>
      <div className="nav-area">
        <div className="logo-container">
          <Link to="/">
          <img src={logo} alt='piaf' className='logo'/>
          </Link>
        </div>
        <div className='text-top'>
          <p>Fransk <br/>Tradition</p>
          <img src={coq} alt='french-rooster' className='image-nav'></img>
        </div>
        <div className='sub-div-navbar'>
          <Navbar/>
          <a href='https://book.easytablebooking.com/book/?id=d74a9&lang=auto' className='menus menu-items' id='special-link'>BORDBESTILLING</a>
        </div>
      </div>
    </header>
  );
};

export default Header;