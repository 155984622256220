import FacebookIcon from '@mui/icons-material/Facebook';

export default function Footer () {
    return (
        <section className="footer-cont">
            <p className='text-footer'>Brasserie Piaf • Jægergårdsgade 40, Aarhus - 8000</p>
            <a href="tel:+4550904061" className="text-footer">Tlf: 50 90 40 61</a>
            <a href='mailto:piafbrasserie@gmail.com' className='text-footer'>Email: piafbrasserie@gmail.com</a>
            <p className='text-footer'>Find os på Facebook: <a href='https://www.facebook.com/piaf.aarhus'><FacebookIcon sx={{ fontSize: "2rem" }} className="some" /></a></p>
        </section>
    )
}