export default function Kontakt() {
  return(
    <section>
      <div className="frokost-cont">
        <h1 className="big-title-forside">Brasserie Piaf</h1>
        <h2 className="sub-big-title-forside" id="frokost-subtitle">Kontakt</h2>
      </div>
      <div className="button-kontakt-cont">
        <button className='booking-kontakt'><a className='link' href='https://book.easytablebooking.com/book/?id=d74a9&lang=auto'>BOOK ET BORD</a></button>
      </div>
      <section className="kontakt-cont">
        <div className="kontakt-left">
          <div className="opening-hours">
            <h1 className="kontakt-title">Åbningstider:</h1>
            <p className="kontakt-text">Mandag: 11:00 - 23:00</p>
            <p className="kontakt-text">Tirsdag: 11:00 - 23:00</p>
            <p className="kontakt-text">Onsdag: 11:00 - 23:00</p>
            <p className="kontakt-text">Torsdag: 11:00 - 23:00</p>
            <p className="kontakt-text">Fredag: 11:00 - sent</p>
            <p className="kontakt-text">Lørdag: 11:00 - sent</p>
            <p className="kontakt-text">Søndag: Lukket</p>
          </div>
          <div className="kontakt-info-cont">
            <a href="tel:+4550904061" className="kontakt-info">Tlf: 50 90 40 61</a><br/>
            <a href='mailto:piafbrasserie@gmail.com' className='kontakt-info'>Email: piafbrasserie@gmail.com</a>
          </div>
        </div>
        <div className="kontakt-right">
          <div className="mapouter">
            <div className="gmap_canvas">
              <iframe width="100%" height="300" id="gmap_canvas" src="https://maps.google.com/maps?q=J%C3%A6gerg%C3%A5rdsgade%2040&t=&z=13&ie=UTF8&iwloc=&output=embed" title="googlemaps"></iframe>
            </div>
          </div>
        </div>
      </section>
    </section>
  )
}