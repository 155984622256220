export const menuItems = [
  {
    title: 'FORSIDE',
    url: '/',
  },
  {
    title: 'MENU',
    submenu: [
      {
        title: 'FROKOST',
        url: 'frokost',
      },
      {
        title: 'AFTENKORT',
        url: 'aftenkort',
      },
      {
        title: 'SÆSON MENU',
        url: 'seasonmenu',
      },
    ],
  },
  {
    title: 'KONTAKT',
    url: '/kontakt',
  },
];